import React from "react";
import serviceS1 from "@/images/web.jpg";
import serviceS2 from "@/images/planingg.jpg";
const ServiceDetailsContent = () => {
  return (
    <div className="serviceArea">
      <img src={serviceS1} alt="" />
      <h2>Web Development</h2>
      <p>
        At Solxk, we are dedicated to designing captivating and functional
        web experiences that are precisely tailored to fulfill your distinct
        business requirements. Our team of experienced developers, designers,
        and strategists collaborates meticulously to bring your vision to life,
        pixel by pixel. We are committed to creating more than just websites; we
        create digital experiences that engage, inspire, and convert. Our
        approach to web development seamlessly integrates cutting-edge
        technology with creative innovation, ensuring that your online presence
        not only stands out but also delivers measurable results.
      </p>
      <div className="row gaping">
        <div className="col-lg-6 col-sm-12 col-md-6">
          <img src={serviceS2} alt="" />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6">
          <h3>planning & strategy</h3>
          <p>Services We Offer:</p>
          <ul>
            <li>
              <i className="fa fa-check-square"></i>Custom Web Development
            </li>
            <li>
              <i className="fa fa-check-square"></i>E-commerce Solutions:Empower
              your business
            </li>
            <li>
              <i className="fa fa-check-square"></i>Responsive Design
            </li>
            <li>
              <i className="fa fa-check-square"></i>Content Management Systems
              (CMS)
            </li>
            <li>
              <i className="fa fa-check-square"></i>Web Application Development
            </li>
          </ul>
        </div>
      </div>
      <p>
        Whether you are a startup aiming to establish your digital footprint or
        a well-established enterprise seeking to revamp your online strategy,
        Solxk is here to assist you. Contact us today to discuss your project
        and take the first step towards unlocking the full potential of your
        online presence.
      </p>
      <p>
        At Solxk, we recognize that the foundation of a successful web
        development project lies in meticulous planning. Before writing a single
        line of code or designing a single pixel, we invest time in
        understanding your business goals, target audience, and unique
        requirements. Our comprehensive planning process ensures that every
        aspect of your project is carefully considered and executed with
        precision.
      </p>
    </div>
  );
};

export default ServiceDetailsContent;
