import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import ServiceDetails from "@/components/service-details-website";
import CallToActionOne from "@/components/call-to-action-one";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import HeaderOne from "@/components/header-one";
import SEO from "@/components/seo";

const WebsiteDevelopment = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <SEO
          title="Solxk - Website Development"
          description="Discover Solxk' professional website development services. We specialize in creating robust, scalable, and secure websites tailored to meet the needs of developers and businesses."
        />
        <Layout PageTitle="WebsiteDevelopment">
          <HeaderOne />
          <PageBanner title="Website Development" name="Service" />
          <ServiceDetails />
          <CallToActionOne extraClassName="ready" />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default WebsiteDevelopment;
